import Vue from 'vue'
import Router from 'vue-router'
import menuModule from '@/store/modules/menu';

const DefaultContainer = () => import('@/containers/DefaultContainer')
const Dashboard = () => import('@/views/Dashboard')
const DbManager = () => import('@/views/manager/db_manager')
const LayerQuery = () => import('@/views/manager/layer_query')
const LayerQuerySecond = () => import('@/views/manager/layer_query_second')
const ErrorLog = () => import('@/views/manager/error_log')
const RequestLog = () => import('@/views/manager/request_log')
const UserList = () => import('@/views/manager/user_list')
const UserAdd = () => import('@/views/manager/user_manage')
const UserEdit = () => import('@/views/manager/user_manage')
const WdmLayers = () => import('@/views/manager/wdmlayers')
const LayerDetails = () => import('@/views/manager/layerdetails')
const LayerReport = () => import('@/views/manager/layer_report')
const LayerProfileDetails = () => import('@/views/manager/layerprofiledetails')
const LayerWdmrRecordView = () => import('@/views/manager/layer_wdmr_record_view')
const WmanagerAppProcessList = () => import('@/views/manager/wmanager_app_process_list')
const WruleengineAppProcessList = () => import('@/views/manager/wruleengine_app_process_list')
const WanalyzerAppProcessList = () => import('@/views/manager/wanalyzer_app_process_list')
const WalgorithmAppProcessList = () => import('@/views/manager/walgorithm_app_process_list')
const UserSettings = () => import('@/views/user')
const Quiz = () => import('@/views/user/quiz')
const Login = () => import('@/views/Login')
const Logout = () => import('@/views/Logout')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [{
      path: '/',
      redirect: 'dashboard',
      name: 'Home',
      component: DefaultContainer,
      children: [{
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: '/user',
          name: 'user_settings',
          component: UserSettings
        },
        {
          path: '/quiz',
          name: 'quiz',
          component: Quiz
        },
        {
          path: '/wdmlayers',
          name: 'wdmlayers',
          component: WdmLayers
        },
        {
          path: '/layerdetails',
          name: 'layerdetails',
          component: LayerDetails
        },
        {
          path: '/layer_report',
          name: 'layer_report',
          component: LayerReport
        },
        {
          path: '/layerprofiledetails',
          name: 'layerprofiledetails',
          component: LayerProfileDetails
        },
        {
          path: '/layer-wdmr-record-view',
          name: 'layerwdmrrecordview',
          component: LayerWdmrRecordView
        },
        {
          path: '/requestlog',
          name: 'requestlog',
          component: RequestLog
        },
        {
          path: '/userlist',
          name: 'userlist',
          component: UserList
        },
        {
          path: '/useradd',
          name: 'useradd',
          component: UserAdd
        },
        {
          path: '/useredit',
          name: 'useredit',
          component: UserEdit
        },
        {
          path: '/errorlog',
          name: 'errorlog',
          component: ErrorLog
        },
        {
          path: '/layerquery',
          name: 'layerquery',
          component: LayerQuery
        },
        {
          path: '/db_manager',
          name: 'db_manager',
          component: DbManager
        },
        {
          path: '/layerquerysecond',
          name: 'layerquerysecond',
          component: LayerQuerySecond
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout
    }
  ]
})
