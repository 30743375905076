// import lazyLoading from '../lazyLoading';

export default {
  name: 'layerwdmrrecordview',
  caption: 'Veri Kayıt & Gör.',
  wdm16_id: '',
  icon: 'icon-book-open',
  path: '/layer-wdmr-record-view',
  // component: lazyLoading('patient/index'),
  required: [],
  permissions: [],
  meta: {
    required: [],
    icon: 'fa-bars',
    expanded: false
  }
};