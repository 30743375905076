import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  save (data) {
    var u_uuid = 'new';
    if (data.old_username) {
      u_uuid = data.old_username;
    }
    let url = API_BASE_URL + 'users/' + u_uuid;
    console.log('URL : ', url, data);
    return Vue.http.put(url, data);
  },
  saveSettings (data) {
    return Vue.http.put(API_BASE_URL + 'users/saveSettings', data);
  },
  register (data) {
    return Vue.http.post(API_BASE_URL + 'users/register', data);
  },
  getUsersForManager (data) {
    return Vue.http.post(API_BASE_URL + 'users/getUsersForManager', data);
  },
  get_users_info_array (data) {
    return Vue.http.post(API_BASE_URL + 'users/get_users_info_array', data);
  },
  agree_agreement (data) {
    return Vue.http.post(API_BASE_URL + 'users/agree_agreement', data);
  },
  email_approve (data) {
    return Vue.http.post(API_BASE_URL + 'users/email_approve', data);
  },
  clean_visited_patients (data) {
    return Vue.http.post(API_BASE_URL + 'users/clean_visited_patients', data);
  },
  change_my_hospital (data) {
    return Vue.http.post(API_BASE_URL + 'users/change_my_hospital', data);
  },
  saveWdmFavoriteList (data) {
    return Vue.http.put(API_BASE_URL + 'users/saveWdmFavoriteList', data);
  },
  save_screen_mode_list (data) {
    return Vue.http.put(API_BASE_URL + 'users/save_screen_mode_list', data);
  },
  saveWdmBriefList (data) {
    return Vue.http.put(API_BASE_URL + 'users/saveWdmBriefList', data);
  },
  savePatientChemoListOperationList (data) {
    return Vue.http.put(API_BASE_URL + 'users/savePatientChemoListOperationList', data);
  },
  savePatientTreatmentListHideOldDays (data) {
    return Vue.http.put(API_BASE_URL + 'users/savePatientTreatmentListHideOldDays', data);
  },
  saveDepartmentFavoriteList (data) {
    return Vue.http.put(API_BASE_URL + 'users/saveDepartmentFavoriteList', data);
  },
  changeActiveUserDepartment (data) {
    return Vue.http.post(API_BASE_URL + 'users/changeActiveUserDepartment', data);
  },
  search (query) {
    return Vue.http.get(API_BASE_URL + 'users/list?' + query);
  },
  prepare_promocodes () {
    return Vue.http.get(API_BASE_URL + 'users/prepare_promocodes');
  },
  check_for_account_list (data) {
    return Vue.http.post(API_BASE_URL + 'users/check_for_account_list', data);
  },
  reset_password (data) {
    return Vue.http.post(API_BASE_URL + 'users/reset_password', data);
  },
  get_grantable_permissions (data) {
    return Vue.http.post(API_BASE_URL + 'users/get_grantable_permissions', data);
  },
  update_user_wdm248 (data) {
    return Vue.http.post(API_BASE_URL + 'users/update_user_wdm248', data);
  },
  get_active_and_with_perm_user_list (data) {
    return Vue.http.post(API_BASE_URL + 'users/get_active_and_with_perm_user_list', data);
  },
  get_users_with_filter (data) {
    return Vue.http.post(API_BASE_URL + 'users/get_users_with_filter', data);
  },
  quick_new_user (data) {
    return Vue.http.post(API_BASE_URL + 'users/quick_new_user', data);
  },
  get_user_for_cloning (data) {
    return Vue.http.post(API_BASE_URL + 'users/get_user_for_cloning', data);
  },
  get_all_users () {
    return Vue.http.get(API_BASE_URL + 'users/all_users');
  },
  getAllUserDetails (query) {
    return Vue.http.get(API_BASE_URL + 'users/getAllUserDetails');
  },
  getUsersForUserSettings (query) {
    return Vue.http.get(API_BASE_URL + 'users/getUsersForUserSettings?' + query);
  },
  get_store_users () {
    return Vue.http.get(API_BASE_URL + 'users/get_store_users');
  },
  get_active_users_list () {
    return Vue.http.get(API_BASE_URL + 'users/get_active_users_list');
  },
  get_user_perm_list_for_edit (query) {
    return Vue.http.get(API_BASE_URL + 'users/get_user_perm_list_for_edit?' + query);
  },
  get_visited_patient_list () {
    return Vue.http.get(API_BASE_URL + 'users/get_visited_patient_list');
  },
  getUsersWisdomDataModelPermission (query) {
    return Vue.http.get(API_BASE_URL + 'users/getUsersWisdomDataModelPermission?' + query);
  },
  getAllUsersPermissionsWithDepartments () {
    return Vue.http.get(API_BASE_URL + 'users/getAllUsersPermissionsWithDepartments');
  },
  get_all_users_with_departments (query) {
    return Vue.http.get(API_BASE_URL + 'users/get_all_users_with_departments?' + query);
  },
  saveAllUsersForPermission (data) {
    return Vue.http.put(API_BASE_URL + 'users/saveAllUsersForPermission', data);
  },
  get (u_uuid) {
    return Vue.http.get(API_BASE_URL + 'users/' + u_uuid);
  },
  profile () {
    return Vue.http.get(API_BASE_URL + 'users/profile');
  },
  delete (u_uuid) {
    return Vue.http.delete(API_BASE_URL + 'users/' + u_uuid);
  },
  upload_profile_picture (data) {
    return Vue.http.post(API_BASE_URL + 'users/upload_profile_picture', data);
  },
  update_favorite_wdm_parameters (data) {
    return Vue.http.post(API_BASE_URL + 'users/update_favorite_wdm_parameters', data);
  },
  update_dont_show_caption_wdm_parameters (data) {
    return Vue.http.post(API_BASE_URL + 'users/update_dont_show_caption_wdm_parameters', data);
  },
  update_multi_wdmr_schema_settings (data) {
    return Vue.http.post(API_BASE_URL + 'users/update_multi_wdmr_schema_settings', data);
  },
  save_language_change (data) {
    return Vue.http.post(API_BASE_URL + 'users/save_language_change', data);
  },
  update_multi_wdmr_favorite_schema (data) {
    return Vue.http.post(API_BASE_URL + 'users/update_multi_wdmr_favorite_schema', data);
  },
  update_wdm_schema_anatomy (data) {
    return Vue.http.post(API_BASE_URL + 'users/update_wdm_schema_anatomy', data);
  },
  update_wdm_general_settings (data) {
    return Vue.http.post(API_BASE_URL + 'users/update_wdm_general_settings', data);
  }
};
