import store from './store';
import menuModule from '@/store/modules/menu';
var core_uı_routes = [{
    title: true,
    name: 'Theme',
    class: '',
    wrapper: {
      element: '',
      attributes: {}
    }
  }
];

export default {
  items: [],
  f_updateItems: function() {
    this.items = [
      ...generateRoutesFromMenu(),
      ...coreUıRoutesFunc()
    ];
  }
}
// wisdom user can see coreuı features
function coreUıRoutesFunc() {
  let routes = [];
  let user = JSON.parse(localStorage.getItem('user'));
  if (user && user.permissions_result.indexOf('wisdom') !== -1) {
    return core_uı_routes;
  } else {
    return [];
  }
}

function generateRoutesFromMenu() {
  let user = JSON.parse(localStorage.getItem('user'));
  let menu = JSON.parse(JSON.stringify(menuModule.state.items));
  // console.log('generateRoutesFromMenu user', user);
  // console.log('generateRoutesFromMenu menu', menu);
  let routes = [];
  // console.log(store);

  routes = [{
    name: 'Anasayfa',
    url: '/dashboard',
    wdm16_id: '6258',
    icon: 'icon-bubbles',
    badge: { variant: 'primary', text: '...' }
  }];
  for (let i = 0, l = menu.length; i < l; i++) {
    let item = {};
    let tmp_children = [];
    if (menu[i].permissions.length === 0) {
      item = JSON.parse(JSON.stringify(menu[i]));
      if (item.path) {
        item.url = item.path;
      }
      if (item.caption) {
        item.name = item.caption;
      }
    } else {
      for (let item_perm_id in menu[i].permissions) {
        if (menu[i].permissions.length === 0 || (user && user.permissions_result.indexOf(menu[i].permissions[item_perm_id]) !== -1)) {
          item = JSON.parse(JSON.stringify(menu[i]));
          if (item.path) {
            item.url = item.path;
          }
          if (item.caption) {
            item.name = item.caption;
          }
          break;
        }
      }
    }
    if (!menu[i].component) {
      if (menu[i].children) {
        for (let k in menu[i].children) {
          if (menu[i].children[k].sub_permissions.length === 0) {
            item.children[k] = JSON.parse(JSON.stringify(menu[i].children[k]));
            if (menu[i].children[k].path) {
              item.children[k].url = menu[i].children[k].path;
            }
            if (menu[i].children[k].caption) {
              item.children[k].name = menu[i].children[k].caption;
            }
            tmp_children.push(item.children[k]);

          } else {
            for (let child_perm_id in menu[i].children[k].sub_permissions) {
              if ((user && user.permissions_result.indexOf(menu[i].children[k].sub_permissions[child_perm_id]) !== -1)) {
                item.children[k] = JSON.parse(JSON.stringify(menu[i].children[k]));
                if (menu[i].children[k].path) {
                  item.children[k].url = menu[i].children[k].path;
                }
                if (menu[i].children[k].caption) {
                  item.children[k].name = menu[i].children[k].caption;
                }
                tmp_children.push(item.children[k]);
                break;
              }
            }
          }
        }
      }
    }
    if (tmp_children.length > 0) {
      item.children = tmp_children;
    }
    if (Object.keys(item).length !== 0 && (item.url !== '' || item.name !== '' || item.children)) {
      routes.push(item);
    }
  }
  // routes.push({
  //   name: 'Documents',
  //   url: 'https://docs.wisdomera.io/',
  //   icon: 'icon-layers',
  //   attributes: { target: '_blank', rel: 'noopener' }
  // });
  // routes.push({
  //   name: 'Wisdomera.io',
  //   url: 'https://wisdomera.io/',
  //   icon: 'icon-layers',
  //   attributes: { target: '_blank', rel: 'noopener' }
  // });
  // routes.push({
  //   name: 'Youtube',
  //   url: 'https://www.youtube.com/channel/UCd9EwF90j0VikM8LBRxwzLQ',
  //   icon: 'icon-layers',
  //   attributes: { target: '_blank', rel: 'noopener' }
  // });
  // routes.push({
  //   name: 'Register',
  //   url: 'https://register.wisdomera.io/',
  //   icon: 'icon-layers',
  //   class: 'mt-auto',
  //   variant: 'success',
  //   attributes: { target: '_blank', rel: 'noopener' }
  // });

  return routes;
}
